.weui-input {
  height: 100% !important;
}

.border-1px-v {
  position: relative;
  &::after {
    pointer-events: none;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top:1px solid #F5F5F7;
  }
}

.border-1px-h {
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      border-right: 1px solid #F5F5F7;
    }
}

.positive-button{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  text-align:center;
  line-height: 28px;
  color: #3b3b3b;
  box-sizing: border-box;
  background-color: #f94a25;
  border-radius: 15px;
  font-size: 16px;
  text-decoration: none;
  user-select: none;
}

.negative-button{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  text-align:center;
  line-height: 28px;
  color: #3b3b3b;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #E0E0E0;
  border-radius: 15px;
  font-size: 16px;
  text-decoration: none;
  user-select: none;
}
