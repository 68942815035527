
  ul {
      padding: 0;
      margin: 0;
  }
  li {
      list-style: none;
  }
  input {
      outline: none;

      &::-ms-clear {
          display: none;
      }
  }

  a{
    display: inline-block;
    color: #3f53ab;
  }
