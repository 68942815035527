.weui-input {
  height: 100% !important;
}
.border-1px-v {
  position: relative;
}
.border-1px-v::after {
  pointer-events: none;
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #F5F5F7;
}
.border-1px-h {
  position: relative;
}
.border-1px-h::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-right: 1px solid #F5F5F7;
}
.positive-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  text-align: center;
  line-height: 28px;
  color: #3b3b3b;
  box-sizing: border-box;
  background-color: #f94a25;
  border-radius: 15px;
  font-size: 16px;
  text-decoration: none;
  user-select: none;
}
.negative-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  text-align: center;
  line-height: 28px;
  color: #3b3b3b;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #E0E0E0;
  border-radius: 15px;
  font-size: 16px;
  text-decoration: none;
  user-select: none;
}
:root,
page {
  /* Nut-ui弹窗样式覆盖 */
  /* 表单样式覆盖 */
  /* Taro showModal样式覆盖 */
}
:root .nut-popup--center.round,
page .nut-popup--center.round {
  border-radius: 4px;
}
:root .nut-dialog .nut-dialog__header,
page .nut-dialog .nut-dialog__header {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  height: 30px;
  line-height: 30px;
  color: #15161B;
  text-align: left;
}
:root .nut-dialog .nut-dialog__content,
page .nut-dialog .nut-dialog__content {
  color: #545762;
  margin: 8px 20px;
  text-align: left;
}
:root .nut-dialog .nut-dialog__footer .nut-button--round,
page .nut-dialog .nut-dialog__footer .nut-button--round {
  border-radius: 4px;
}
:root .nut-dialog .nut-dialog__footer .nut-dialog__footer-cancel,
page .nut-dialog .nut-dialog__footer .nut-dialog__footer-cancel {
  background: #EEEEEE;
  color: #15161B;
  border: none;
}
:root .nut-dialog .nut-dialog__footer .nut-dialog__footer-ok,
page .nut-dialog .nut-dialog__footer .nut-dialog__footer-ok {
  background: #F94A25;
  color: #FFFFFF;
}
:root .nut-form .nut-cell-group__wrap,
page .nut-form .nut-cell-group__wrap {
  box-shadow: none;
}
:root .nut-form .nut-cell-group__wrap .nut-form-item__label,
page .nut-form .nut-cell-group__wrap .nut-form-item__label {
  font-size: 14px;
  color: #111111;
  line-height: 20px;
}
:root .taro-modal__content .taro-modal__title,
page .taro-modal__content .taro-modal__title {
  font-weight: 500;
  font-size: 20px;
  color: #15161B;
  line-height: 28px;
  text-align: left;
}
:root .taro-modal__content .taro-modal__text,
page .taro-modal__content .taro-modal__text {
  font-size: 14px;
  color: #545762;
  line-height: 20px;
  text-align: left;
}
:root .taro-modal__content .taro-modal__foot:after,
page .taro-modal__content .taro-modal__foot:after {
  border: none;
}
:root .taro-modal__content .taro-modal__foot .taro-model__confirm,
page .taro-modal__content .taro-modal__foot .taro-model__confirm {
  font-family: PingFangSC, PingFang SC;
  color: #FFFFFF !important;
  font-weight: 600;
  font-size: 15px;
  height: 36px;
  line-height: 36px;
  background: #F94A25;
  border-radius: 4px;
  margin: 10px 20px 20px 20px;
}
:root .taro-modal__content .taro-modal__foot .taro-model__cancel,
page .taro-modal__content .taro-modal__foot .taro-model__cancel {
  font-family: PingFangSC, PingFang SC;
  color: #15161B !important;
  font-size: 15px;
  height: 36px;
  line-height: 36px;
  background: #EEEEEE;
  border-radius: 4px;
  margin: 10px 20px 20px 20px;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
input {
  outline: none;
}
input::-ms-clear {
  display: none;
}
a {
  display: inline-block;
  color: #3f53ab;
}
