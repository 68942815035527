

:root,
page {
  .nut-popup--center.round {
    border-radius: 4px;
  }
  /* Nut-ui弹窗样式覆盖 */
  .nut-dialog{
    .nut-dialog__header{
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      height: 30px;
      line-height: 30px;
      color: #15161B;
      text-align: left;
    }
    .nut-dialog__content{
      color: #545762;
      margin: 8px 20px;
      text-align: left;
    }
    .nut-dialog__footer {
      .nut-button--round{
        border-radius: 4px;
      }
      .nut-dialog__footer-cancel{
        background: #EEEEEE;
        color: #15161B;
        border: none;
      }
      .nut-dialog__footer-ok{
        background: #F94A25;
        //font-weight: 600;
        //font-size: 15px;
        color: #FFFFFF;
      }

    }
  }

  /* 表单样式覆盖 */
  .nut-form{
    .nut-cell-group__wrap{
      box-shadow: none;
      .nut-form-item__label{
        font-size: 14px;
        color: #111111;
        line-height: 20px;
      }
    }
  }

  /* Taro showModal样式覆盖 */
  .taro-modal__content{
    .taro-modal__title{
      font-weight: 500;
      font-size: 20px;
      color: #15161B;
      line-height: 28px;
      text-align: left;
    }
    .taro-modal__text{
      font-size: 14px;
      color: #545762;
      line-height: 20px;
      text-align: left;
    }
    .taro-modal__foot{
      &:after{
        border: none;
      }
      .taro-model__confirm{
        font-family: PingFangSC, PingFang SC;
        color: #FFFFFF !important;
        font-weight: 600;
        font-size: 15px;
        height: 36px;
        line-height: 36px;
        background: #F94A25;
        border-radius: 4px;
        margin: 10px 20px 20px 20px;
      }
      .taro-model__cancel{
        font-family: PingFangSC, PingFang SC;
        color: #15161B !important;
        font-size: 15px;
        height: 36px;
        line-height: 36px;
        background: #EEEEEE;
        border-radius: 4px;
        margin: 10px 20px 20px 20px;
      }
    }

  }

}
